import { useCallback, useEffect, useState } from 'react'
import { useAuth } from './useAuth'

export interface UseAccessTokenOptions {
  lazy?: false
}
export interface UseAccessTokenOptionsLazy {
  lazy: true
}

export type UseAccessToken = [UseAccessTokenState]
export type UseAccessTokenLazy = [UseAccessTokenState, GetAccessToken]

export interface UseAccessTokenState {
  token: string | undefined
  loading: boolean
  error: Error | undefined
  called: boolean
}
export type GetAccessToken = () => Promise<string | undefined>

const defaultOptions: UseAccessTokenOptions = {
  lazy: false,
}

export function useAccessToken({ lazy }: UseAccessTokenOptionsLazy): UseAccessTokenLazy
export function useAccessToken({ lazy }: UseAccessTokenOptions): UseAccessToken
export function useAccessToken(): UseAccessToken
export function useAccessToken({
  lazy,
}: UseAccessTokenOptions | UseAccessTokenOptionsLazy = defaultOptions):
  | UseAccessToken
  | UseAccessTokenLazy {
  const { getAccessTokenSilently } = useAuth()
  const [called, setCalled] = useState(!lazy)
  const [token, setToken] = useState<string | undefined>()
  const [error, setError] = useState<Error | undefined>()

  const loading = !token && !error

  const getToken: GetAccessToken = useCallback(async () => {
    try {
      setCalled(true)
      const accessToken = await getAccessTokenSilently()
      setToken(accessToken)
      return accessToken
    } catch (accessTokenError) {
      console.error('useAccessToken error', accessTokenError)
      setError(
        accessTokenError instanceof Error ? accessTokenError : new Error(`${accessTokenError}`)
      )
      setToken(undefined)
      return undefined
    }
  }, [getAccessTokenSilently])

  useEffect(() => {
    if (!lazy) {
      getToken()
    }
  }, [lazy, getToken])

  if (lazy) {
    return [{ token, loading, error, called }, getToken]
  }
  return [{ token, loading, error, called }]
}
